import React, { Component } from "react"
import styled, { css } from 'styled-components'
import { Link, navigate } from 'gatsby'
import { find } from 'lodash'

import { LandingBlock, Layout } from '../components'

import { bgImage, padding, bgIcon, hoverState } from "../styles/global"
import { red, blue, pink, yellow } from "../styles/colors";
import { media } from "../styles/utils";

const Landing = (props) => {
    const data = props.pageContext;

    const renderBlocks = (blocks) => {
		if (!data.blocks) return;

		return data.blocks.map((block, i) => {  
            const noContainer = ['image', 'video', 'divider', 'image_link_grid'];

			return (
				<LandingBlock
                    layout={block.acf_fc_layout}
                    wrapContainer={!noContainer.includes(block.acf_fc_layout)}
                    nextBlock={data.blocks[i + 1]}
                    lastBlock={i == (data.blocks.length - 1)}
					key={i}
					{...block}
				/>
			)
		})
    }
    
    const renderHeaderSocial = () => {
        if (!data.header_social_links?.length) return;

        const colours = [red, blue, '#569350', yellow]
        
        const items = data.header_social_links.map((item, i) => {
            return (
                <HeaderSocialLink
                    href={item.link}
                    target={'_blank'}
                    background={colours[i % colours.length]}
                >
                    <SocialImg
                        key={i}
                        src={resolveSocialIcon(item.type)}
                    />
                </HeaderSocialLink>
            )
        })

        return (
            <HeaderSocialLinks>
               {items}
            </HeaderSocialLinks>
        )
    }

    const renderHero = () => {
        return (
            <Hero>
                <Info>
                    <Logo
                        as={Link}
                        to={'/'}
                    />

                    <Heading
                        dangerouslySetInnerHTML={{__html: data.heading}}
                    />

                    {renderHeaderSocial()}
                </Info>

                <Subtitle>
                    <Heading
                        dangerouslySetInnerHTML={{__html: data.subheading}}
                    />
                </Subtitle>
            </Hero>
        )
    }

    const resolveSocialIcon = (type) => {

        const icons = {
            'spotify': require('../assets/icons/landing-social/spotify.svg'),
            'soundcloud': require('../assets/icons/landing-social/soundcloud.svg'),
            'apple-music': require('../assets/icons/landing-social/apple.svg'),
            'bandcamp': require('../assets/icons/landing-social/bandcamp.svg'),
            'twitter': require('../assets/icons/landing-social/twitter.svg'),
            'youtube': require('../assets/icons/landing-social/youtube.svg'),
            'facebook': require('../assets/icons/landing-social/facebook.svg'),
            'instagram': require('../assets/icons/landing-social/instagram.svg'),
        }

        if (icons[type]) return icons[type]
    }
    
    const renderSocialLinks = () => {
        if (!data.social_links?.length) return;
        
        const items = data.social_links.map((item, i) => {
            return (
                <a
                    href={item.link}
                    target={'_blank'}
                >
                    <SocialLink 
                        key={i}
                        src={resolveSocialIcon(item.type)}
                    />
                </a>
            )
        })

        return (
            <Links>
                <Subheading>
                    Follow the artist
                </Subheading>
                <Items>
                    {items}
                </Items>
            </Links>
        )
    }

    const renderSocial = () => {
        return (
            <Social>
                <AboutText
                    dangerouslySetInnerHTML={{__html: data.footer_text}}
                />
                
                {renderSocialLinks()}
            </Social>
        )
    }

    const renderFooter = () => {
        return (
            <Footer>
                <Link
                    to={'/'}
                >
                    <BackTo
                        breakpoint={'mobile'}
                        src={require('../assets/images/backto-mobile.svg')}
                    />

                    <BackTo
                        breakpoint={'desktop'}
                        src={require('../assets/images/backto-desktop.svg')}
                    />
                </Link>
                <Text
                    dangerouslySetInnerHTML={{__html: `
                        Music in Exile, all rights reserved 2020 © Music in Exile acknowledges the cultures of our First Nations Peoples and are thankful for the community that we share together now. We pay our respects to our First Nations Peoples and their elders/leaders, both past and present, and emerging.
                    `}}
                />
            </Footer>
        )
    }

    return (
        <Layout>
            <Wrapper>
                {renderHero()}

                <Blocks>
                    {renderBlocks()}
                </Blocks>

                {renderSocial()}
                {renderFooter()}
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Text = styled.div``
const Info = styled.div``
const Item = styled.div``
const Items = styled.div``

const BGImage = styled.div`
    background-image: url(${props => props.image});  
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #FADE4B;
    min-height: 100vh;

    .block_image {
        max-width: 880px;
        justify-content: center;
        margin: 0 auto;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1680px;
`

// Hero

const Subtitle = styled.div``

const Hero = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #F2BDA4;
    
    ${Info} {
        width: 100%;
        height: 400px;
        ${padding}

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        ${media.tablet`
            height: 374px;
            position: relative;
        `}

        ${Heading} {
            font-family: 'Zipper';
            font-weight: 500;
            font-size: 96px;
            line-height: 79.5%;
            max-width: 1100px;
            padding: 0 160px;
            text-align: center;
            padding-top: 48px;
            
            ${media.tablet`
                padding: 0 20px;
                padding-top: 48px;
                font-size: 58px;
            `}
        }
    }

    ${Subtitle} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background: ${blue};
        ${padding}
        padding-top: 18px;
        padding-bottom: 24px;

        border-top: 2px solid black;
        border-bottom: 2px solid black;

        ${media.tablet`
            padding-top: 10px;
            padding-bottom: 16px;
        `}

        ${Heading} {
            font-family: 'Cooper';
            font-weight: 300;
            font-size: 24px;
            line-height: 29px;
            color: white;
            max-width: 870px;
            text-align: center;

            ${media.tablet`
                font-size: 18px;
                line-height: 22px;
            `}
        }
    }
`

// Logo

const Logo = styled.div`
    width: 231px;
    height: 86px;
    background-image: url(${require('../assets/images/landing-page-logo.svg')});
    ${bgIcon};

    position: fixed;
    top: 30px;
    left: 26px;
    z-index: 10;

    ${media.tablet`
        width: 150px;
        height: 55px;
        position: absolute;
        top: 26px;
        left: 50%; 
        transform: translateX(-50%);
    `}
`

// Header Social Links

const HeaderSocialLinks = styled.div`
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 10;
    display: flex;
    border: 2px solid black;

    ${media.tablet`
        position: absolute;
        top: auto;
        bottom: -2px;
        right: 0;
        border-right: 0;w
    `}
`

const HeaderSocialLink = styled.a`
    width: 85px;
    height: 81px;
    padding: 20px;
    box-sizing: border-box;
    background: red;
    background: ${props => props.background};

    &:not(:last-child) {
        border-right: 2px solid black;
    }

    ${media.tablet`
        width: 48px;
        height: 48px;
        padding: 10px;
    `}
`

const SocialImg = styled.img`
    width: 100%;
    height: auto;
    transform: translateY(-2px);

    ${media.phone`
        transform: translateY(0px);
    `}
`

// Blocks

const Blocks = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    width: 100%;
    padding: 0 30px; 
    box-sizing: border-box;

    ${media.tablet`
        margin-top: 24px;
        padding: 0 20px; 
    `}
`

// Social Links

const AboutText = styled.div``

const Social = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${padding}

    ${media.tablet`
        padding: 0 20px; 
    `}

    ${AboutText} {
        margin-left: calc(50% + 20px);
        margin-right: calc(25% + 20px);
        font-family: 'Cooper';
		font-size: 26px;
		line-height: 31px;

        ${media.tablet`
            margin-left: 0;
            margin-right: 0;
        `}
    }    
` 

const Links = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    padding-bottom: 24px;
            
    ${Subheading} {
        font-family: 'Cooper';
        font-size: 26px;
        line-height: 31px;
        margin-bottom: 32px;
    }

    ${Items} {
        display: flex;
        align-items: center;    
        flex-flow: row wrap;

        ${media.tablet`
            width: calc(100% + 32px);
        `}
    }
`

const SocialLink = styled.img`
    width: auto;
    height: 157px;
    max-width: 248px;
    margin-right: 32px;
    ${hoverState}

    ${media.tablet`
        height: 90px;
        max-width: 128px;
        margin-bottom: 32px;
    `}
`


// Back To

const BackTo = styled.img`
    ${props => {
        if (props.breakpoint == 'mobile') return css`
            display: none;

            ${media.tablet`
                display: flex;
                max-width: 100%;
            `}
        `

        if (props.breakpoint == 'desktop') return css`
          
            ${media.tablet`
                display: none;
            `}
        `
    }}
`

const Footer = styled.div`
    width: 100%;
    background: #F2BDA4;
    ${padding};
    padding-top: 96px;
    padding-bottom: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
 

    ${Text} {
        font-family: 'Cooper';
        font-size: 18px;
        line-height: 22px;
        margin-top: 24px;
    }

    ${media.tablet`
        padding-top: 27px;
    `}
`

export default Landing

